import * as React from "react"
import * as styles from './404.module.css'
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main>
      <title>404 Seite nicht gefunden | Ninas Beauty Oase | Kosmetik & Fußpflege | Grevenbroich-Wevelinghoven</title>
      <section className={styles.section404}>
        <Link to="/" className={styles.h2404}>Ninas Beauty Oase</Link>

        <h1 className={styles.h1404}>404</h1>
        <p className={styles.p404}>
          Die gesuchte Seite wurde leider nicht gefunden.
        </p>


        <Link to="/" className={styles.a404}>Zur Startseite</Link>
        <Link to="/#footer" className={styles.a404}>Impressum</Link>
      </section>
    </main>
  )
}

export default NotFoundPage
